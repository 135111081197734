import React, { useEffect, useState } from 'react'
import { ImageWithBasePath } from './ImageWithBasePath'

export const FiltersIconsHandler = ({
  icon,
  imageDetails = { height: 15, width: 15 }
}: {
  icon: string
  imageDetails?: { height: number; width: number }
}) => {
  const [iconName, setIconName] = useState<string>('')
  useEffect(() => {
    if (icon === 'Veg') {
      setIconName('vegIcon')
    } else if (icon === 'Non-Veg') {
      setIconName('nonVegIcon')
    } else if (icon === 'Vegan') {
      setIconName('veganIcon')
    } else if (icon === 'Halal') {
      setIconName('halalIcon')
    } else {
      setIconName('')
    }
  }, [icon])
  return iconName ? (
    <ImageWithBasePath
      src={iconName}
      height={imageDetails.height}
      width={imageDetails.width}
      alt=""
    />
  ) : null
}

import { useRouter } from 'next/router'
import React, { useEffect, useContext } from 'react'
import { AppContext } from './Layout'
import { BackHandlerProps } from '../ts/types/all.types'

export const BackHandler = ({
  orderPlaced,
  stripePromise,
  setOrderPlaced,
  previousRoute,
  modalOpen,
  setModalOpen
}: BackHandlerProps) => {
  const {
    setAddressModalOpen,
    addressModalOpen,
    setShowCustomisationScreen,
    setOpenCustomisationModal,
    openCustomisationModal,
    showCustomisationScreen,
    setIsReviewModalOpen,
    isReviewModalOpen,
    openAskCustomiseAgainModal,
    setopenAskCustomiseAgainModal,
    isAddressOpen,
    setIsAddressOpen,
    isEditProfileOpen,
    setIsEditProfileOpen
  } = useContext(AppContext)

  const router = useRouter()

  const fun = async () => {
    if (previousRoute) {
      await router.push({ pathname: previousRoute })
    } else if (orderPlaced && stripePromise) {
      // @ts-ignore
      setOrderPlaced(false)
      history?.forward()
    } else if (addressModalOpen) {
      setAddressModalOpen(false)
      history?.forward()
    } else if (showCustomisationScreen || openCustomisationModal) {
      setShowCustomisationScreen(false)
      setOpenCustomisationModal(false)
    } else if (isReviewModalOpen) {
      setIsReviewModalOpen(false)
      history?.forward()
    } else if (openAskCustomiseAgainModal) {
      setopenAskCustomiseAgainModal(false)
    } else if (modalOpen) {
      // @ts-ignore
      setModalOpen(false)
      history?.forward()
    } else if (!isAddressOpen) {
      setIsAddressOpen(true)
      history?.forward()
    } else if (isEditProfileOpen) {
      setIsEditProfileOpen(false)
      history?.forward()
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', fun)
    return () => {
      window.removeEventListener('popstate', fun)
    }
  }, [
    addressModalOpen,
    showCustomisationScreen,
    openCustomisationModal,
    isReviewModalOpen,
    orderPlaced,
    stripePromise,
    openAskCustomiseAgainModal,
    modalOpen,
    isAddressOpen,
    isEditProfileOpen
  ])

  return <div></div>
}

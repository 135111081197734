import classNames from 'classnames'
import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'
import { PRODUCT } from '../ts/interfaces/product.interface'
import { CartItemProps } from '../ts/types/cartItem.types'
import { NoOfItemsSelectorProps } from '../ts/types/all.types'

export const NoOfItemsSelector = ({
  value = 1,
  foodDetails,
  imageDetails = { height: 14, width: 14 },
  className = '',
  valueClassName = '',
  imageClassName = '',
  style = {
    height: '',
    width: ''
  },
  forCart,
  item,
  forHome,
  index
}: NoOfItemsSelectorProps) => {
  const { setCartItems, theme, setopenAskCustomiseAgainModal, setCustomisationItem, cartItems } =
    useContext(AppContext)

  return (
    <div className={classNames('flex items-center', className)}>
      <div
        className={classNames('cursor-pointer')}
        onClick={() => {
          if (forCart) {
            setCartItems((prev: CartItemProps[]) => {
              let previousItems = [...prev]
              //@ts-ignore
              if (previousItems[index]?.quantity - 1 === 0) {
                //@ts-ignore
                previousItems.splice(index, 1)
              } else {
                 //@ts-ignore
                previousItems[index].quantity -= 1
              }
              return previousItems
            })
          } else if (forHome) {
            setCartItems((prev: CartItemProps[]): any[] => {
              let previousItems = [...prev]
              previousItems.map((item1: any, index: number) => {
                if (item1?.foodDetails?.title === item?.foodDetails?.title) {
                  if (foodDetails?.productVariants?.length === 0) {
                    if (item1.quantity - 1 === 0) {
                      previousItems.splice(index, 1)
                    } else {
                      item1.quantity -= 1
                    }
                  }
                }
              })
              return previousItems
            })
          } else {
            setopenAskCustomiseAgainModal(true)
            setCustomisationItem({
              foodDetails,
              quantity:
                cartItems.find((item: any) => item?.foodDetails?.title === foodDetails?.title)
                  ?.quantity || 1,
              customisation: [],
              extraPrice: 0,
              customisationString: ''
            })
          }
        }}
      >
        <div
          className={classNames(
            'flex cursor-pointer items-center  justify-center border-[1px] border-solid bg-[white] p-[2px]',
            imageClassName
          )}
          style={{ borderColor: theme.gradient, ...style }}
        >
          <ImageWithBasePath
            src="subtractIcon"
            height={imageDetails.height}
            width={imageDetails.width}
          />
        </div>
      </div>
      <div className={classNames('bg-[white] font-black font-[500]', valueClassName)}>{value}</div>
      <div
        className={classNames(
          'flex cursor-pointer items-center justify-center p-[2px] ',
          imageClassName
        )}
        style={{
          background: `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`,
          ...style
        }}
        onClick={() => {
          if (forCart) {
            setCartItems((prev: CartItemProps[]) => {
              let previousItems = [...prev]
              //@ts-ignore
              previousItems[index].quantity += 1
              return previousItems
            })
          } else if (forHome) {
            setCartItems((prev: CartItemProps[]) => {
              let previousItems = [...prev]
              previousItems.map((item1: any) => {
                if (item1?.foodDetails?.title === item?.foodDetails?.title) {
                  if (foodDetails?.productVariants?.length === 0) {
                    item1.quantity += 1
                  }
                }
              })
              return previousItems
            })
          } else {
            setopenAskCustomiseAgainModal(true)
            setCustomisationItem({
              foodDetails,
              quantity:
                cartItems.find(
                  (item: CartItemProps) => item?.foodDetails?.title === foodDetails?.title
                )?.quantity || 1,
              customisation: [],
              extraPrice: 0,
              customisationString: ''
            })
          }
        }}
      >
        <ImageWithBasePath src="addIcon" height={imageDetails.height} width={imageDetails.width} />
      </div>
    </div>
  )
}

import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { FiltersIconsHandler } from './FiltersIconsHandler'
import { NoOfItemsSelector } from './NoOfItemsSelector'
import { RightArrowIcon } from '../svgIcons/rightArrowIcon'
import { CartItemProps } from '../ts/types/cartItem.types'

export const DesktopCartItemsShow = ({ className = '' }: { className: string }): JSX.Element => {
  const {
    cartItems,
    setOpenCustomisationModal,
    setReCustomizeItem,
    setHeaderDetails,
    theme,
    tenant
  } = useContext(AppContext)

  return (
    <>
      {cartItems?.map((item: CartItemProps, index: number) => (
        <div className={className}>
          <div className="flex w-[100%] items-center justify-between">
            <div className="w-[50%] space-y-[3px]">
              <div className="flex items-center">
                <div>
                  <FiltersIconsHandler icon={item?.foodDetails?.dishType} />
                </div>
                <div className="ml-[7px] font-black text-sm font-[500]">
                  {item?.foodDetails?.title}
                </div>
              </div>
              <div
                className="mt-[4px] flex h-fit items-center"
                onClick={() => {
                  setOpenCustomisationModal(true)
                  setReCustomizeItem(item)
                  setHeaderDetails({
                    title: 'Cancel',
                    onClick: JSON.stringify(`() => {
                      setShowCustomisationScreen(false)
                      setOpenCustomisationModal(false)
                    }`)
                  })
                }}
              >
                <div className="truncate font-black text-sm font-[500] leading-[16.39px] text-black-50">
                  {item?.customisationString?.substring(0, item?.customisationString.length - 1) ||
                    item?.notes}
                </div>
                {((item?.customisationString && item?.customisationString?.length > 0) ||
                  item?.notes) && (
                  <div className="ml-[2px]">
                    <RightArrowIcon
                      width={'10px'}
                      height={'10px'}
                      fillColor={theme.customPrimary}
                      viewBox="0 0 10 10"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-[50%] gap-[10px] items-end justify-between">
              <div>
                <NoOfItemsSelector
                  imageDetails={{ height: 15, width: 15 }}
                  value={item?.quantity}
                  foodDetails={item.foodDetails}
                  valueClassName="px-[9px] text-md border-t-[0.74px] border-[#E5E5E5] border-b-[0.74px] leading-[19.12px] py-[2px]"
                  imageClassName="rounded-[4px]"
                  style={{ height: '25px', width: '25px' }}
                  forCart={true}
                  item={item}
                  index={index}
                />
              </div>
              <div className="flex items-center gap-[2px]">
                <div>{`${tenant?.currencySymbol}`}</div>
                <div className="ml-[2px] font-black text-md font-[500] leading-[19.12px]">
                  {`${(item?.foodDetails?.price + item?.extraPrice) * (item?.quantity || 1)}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

import { Modal, notification } from 'antd'
import React, { useContext, useState, useEffect } from 'react'
import { AppContext } from './Layout'
import { CloseIcon } from '../svgIcons/closeIcon'
import { ItemCustomization } from './ItemCustomization'
import { Media } from '../media'
import { NotificationType } from '../ts/types/all.types'

export const CustomisationModal = () => {
  const {
    customisationItem,
    setOpenCustomisationModal,
    openCustomisationModal,
    setShowCustomisationScreen,
    theme,
    tenant,
    setCustomisationItem,
    setReCustomizeItem
  } = useContext(AppContext)
  const [modalPrice, setModalPrice] = useState(0)
  const [activatebutton, setActivateButton] = useState({ button: false, slide: false })
  const [applyStyle, setApplyStyle] = useState(false)
  const [activateSave, setActivateSave] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message,
      placement: 'top'
    })
  }

  const modalSlideDownHandler = () => {
    setApplyStyle(true)
    let timeOut = setTimeout(() => {
      setOpenCustomisationModal(false)
      setShowCustomisationScreen(false)
      setApplyStyle(false)
      clearTimeout(timeOut)
    }, 300)
  }
  useEffect(() => {
    if (activatebutton.slide) {
      modalSlideDownHandler()
    }
  }, [activatebutton])

  return (
    <Media greaterThan="sm">
      <Modal
        open={openCustomisationModal}
        className="hideOnMobile"
        onCancel={() => {
          setOpenCustomisationModal(false)
          setShowCustomisationScreen(false)
          setCustomisationItem({})
          setReCustomizeItem({})
        }}
        footer={null}
        closable={false}
        centered
      >
        {contextHolder}
        <div className="flex items-center justify-between px-[24px] shadow-desktopHeaderShadow">
          <div className="py-[12px] font-black text-lg font-[500] leading-[21.86px]">
            <span style={{ color: theme.customPrimary }} className="mr-[5px]">
              Customise
            </span>
            {customisationItem?.foodDetails?.title}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setOpenCustomisationModal(false)
              setShowCustomisationScreen(false)
            }}
          >
            <CloseIcon height="15" width="15" viewBox="0 0 15 15" fillColor={theme.customPrimary} />
          </div>
        </div>
        <div className="max-h-[500px] overflow-y-scroll pt-[24px]">
          <ItemCustomization
            forDesktop={true}
            setModalPrice={setModalPrice}
            activatebutton={activatebutton}
            setActivateButton={setActivateButton}
            setActivateSave={setActivateSave}
          />
        </div>
        <div className="absolute bottom-[0px] left-[0px] flex w-full items-center justify-end border-t-[1px] border-t-[#E5E5E5] bg-[white]">
          <div
            style={{ color: theme.customPrimary }}
            className="mr-[14px] font-black font-black text-[17px] leading-[23.33px]"
          >
            {`Total ${tenant?.currencySymbol} ${
              customisationItem?.foodDetails?.price + modalPrice
            }`}
          </div>
          <div
            className="my-[11px] mr-[24px] cursor-pointer select-none items-center justify-center py-[7.5px] px-[15px] text-center font-black font-[500] leading-[16.39px]"
            onClick={() =>
              activateSave
                ? setActivateButton({ button: true, slide: false })
                : openNotificationWithIcon('error', 'Please select the mandatory fields')
            }
            style={{
              background: activateSave
                ? `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`
                : '#E5E5E5'
            }}
          >
            Save and add
          </div>
        </div>
      </Modal>
      <style jsx global>{`
        .ant-modal .ant-modal-content {
          border-radius: 0px;
          padding: 0px;
        }
      `}</style>
    </Media>
  )
}

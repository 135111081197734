import React, { useContext } from 'react'
import classNames from 'classnames'
import { AppContext } from './Layout'
import { Loader } from './Loader'
import { BottomShowComponentProps } from '../ts/types/all.types'

export const BottomShowComponent = ({
  value,
  buttonText,
  onClick = () => {},
  className,
  buttonClassName,
  loading = false
}: BottomShowComponentProps) => {
  const { theme } = useContext(AppContext)
  return (
    <div
      className={classNames(
        'fixed flex w-[100%] items-center justify-between px-[24px] py-[8px] font-black text-md font-[500] leading-[19.12px]',
        className
      )}
      style={{
        background: `linear-gradient(135deg, ${theme.customPrimary} 0%, ${theme.gradient} 100%)`
      }}
    >
      <div>{value}</div>
      <div
        className={classNames(
          'bg-[#FFFFFF] py-[14.5px] px-[46.5px] font-black text-md font-[500] leading-[19.12px]',
          buttonClassName
        )}
        onClick={onClick}
      >
        {loading ? <Loader /> : buttonText}
      </div>
    </div>
  )
}
